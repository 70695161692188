@tailwind base;
@tailwind components;
@tailwind utilities;

.flex-1 { flex: 1 }

.firebase-emulator-warning {
  display: none;
}

.pointer-on-hover:hover {
  cursor: pointer !important;
}
