.checkout-container form {
  width: 100%;
  align-self: center;
}


.checkout-container #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.checkout-container #payment-element {
  margin-bottom: 24px;
}

@media only screen and (max-width: 600px) {
  .checkout-container form {
    /* width: 80vw; */
    min-width: initial;
  }
}
